.loadingscreen {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0); /* Optional: Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensures it is on top */
  }
  
  .spinner {
    width: 50px;
    height: 50px;
    border: 6px solid rgb(252, 252, 252);
    border-top: 6px solid #000000;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
.Countdown{
    background-color: black;
}

.Countdown-Container{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.displaynone{
    width: 0vw !important;
    height: 0vh !important;
    visibility: hidden;
}

.background-gradient{
    height: 100vh;
    width: 200vw;
    background: linear-gradient(135deg, #0e0e0e, #FFB900 800%);
}

.topBar{
    position: absolute;
    padding-left: 20px;
    padding-top: 20px;
    height: 40px;
}

@media (max-width: 768px) {
    .topBar{
        height: 35px;
    }
}

@media (max-width: 480px) {
    .topBar{
        height: 30px;
    }
}

.content{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.content > .content-header{
    width: 100vw;
    flex: 0.9;
    text-align: center;
    margin-top: 1vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Countdown-Container > .content > .content-header > h1 {
    margin-top: 60px;
    font-weight: 400; 
    font-size: 95px;
    color: #F7C542;
}

/* For tablets (portrait and landscape) */
@media (max-width: 1024px) {
    .Countdown-Container > .content > .content-header > h1 {
        margin-top: 50px;
        font-size: 75px;
    }
}

/* For mobile phones (landscape) */
@media (max-width: 768px) {
    .Countdown-Container > .content > .content-header > h1 {
        margin-top: 40px;
        font-size: 65px;
    }
}

/* For mobile phones (portrait) */
@media (max-width: 480px) {
    .Countdown-Container > .content > .content-header > h1 {
        font-size: 44px;
    }
}

.content > .content-header > .counter {
    width: 90vw; /* Use relative width */
    max-width: 600px;
    margin: 0 auto;
    margin-top: 2vh;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around; /* Adjust spacing between items */
    flex-wrap: wrap; /* Allow wrapping */
}

@media (max-width: 480px) {
    .content > .content-header > .counter {
        width: 100vw; /* Use relative width */
        justify-content: space-between;
        flex-wrap: nowrap; /* Prevent wrapping */
    }
}

.content > .content-mid{
    flex: 1;
    width: 100vw;
    margin-top: 3vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content > .content-mid > .h1s{
    left: 0;
    overflow: hidden;
    height: 15vh;
    position: absolute;
    font-size: 3rem;
    white-space: nowrap;
    color: rgba(255, 255, 255, 0.068);
    display: flex;
    flex-direction: row;
    z-index: 0;
    font-weight: 700;
    font-size: 80px;
    text-transform: uppercase;
    background: radial-gradient(circle, #87878709 0%, #c4c4c410 50%, #d8d8d810 100%);
}

.content > .content-footer{
    flex: 0.45;
    margin-top: 4vh;
    margin: 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content > .content-footer > h1{
    text-align: center;
    color: white;
    font-weight: 350;
    font-size: 30px;
}

.content > .content-footer > .subscribe-container{
    width: 50vw;
    height: 35px;
    max-width: 500px;
    min-width: 400px;
    display: flex;
    flex-direction: row;
}

@media (max-width: 480px) {
    .content > .content-footer > .subscribe-container{
        width: 35vw;
        min-width: 325px;
    }
    .content > .content-footer > h1{
        text-align: center;
        color: white;
        font-weight: 350;
        font-size: 22px;
    }
}

.content > .content-footer > .subscribe-container > .left-side {
    width: 75%;
    height: 100%;
    background: radial-gradient(circle, #87878711 0%, #c4c4c418 50%, #d8d8d81e 100%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 0px solid #ccc;
    border-radius: 10px 0 0 10px;
    outline: none;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
}

.content > .content-footer > .subscribe-container > .right-side{
    width: 25%;
    height: 100%;
    background: radial-gradient(circle, #f7c4426b 1%, #f0b56c85 100%, #f7c44277 300%);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}