
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;600;900&display=swap');
::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #141824; 
  -webkit-border-radius: 0px;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background: #ffffff; 
  -webkit-box-shadow: inset 0 0 6px #fdfdfd; 
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #FFB600; 
}

*{
  margin:0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  font-family: 'Montserrat';
  overflow: hidden !important;
  -webkit-tap-highlight-color: transparent;
  font-optical-sizing: auto;
  max-width: 100vw !important;
}
