.Video {
    width: 100vw;
    height: 70vh;
    min-height: 500px;
    min-width: 888px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Video > .video-inner {
    box-shadow: 2px 2px 50px 2px rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    position: absolute;
    height: 495px;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    border-radius: 20px;
    overflow: hidden;
 /* Ensure the button is positioned relative to the video */
}

.Video iframe {
    border: none; /* Ensures there is no border around the iframe */
    display: block; /* Removes any space below the iframe due to inline-block */

}

.unmut-ebutton {
    position: absolute;
    margin: auto;   
    justify-self: start;
    padding: 10px 20px;
    font-size: 10px;
    color: white;
    top:20px;
    background-color: rgba(255, 255, 255, 0.257); /* Eye-catching tomato color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    z-index: 10; /* Ensures the button stays above the video */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow for better visibility */
    transition: transform 0.2s;
}

.unmut-ebutton:hover {
    transform: scale(1.1); /* Slightly enlarge the button on hover for effect */
}

@media (max-width: 920px) {
    .Video {
        width: 100vw;
        height: 55vh;
        min-height: 386px;
        min-width: 688px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .Video > .video-inner {
        display: flex;
        height: 381px;
        width: 686px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 20px;
        overflow: hidden;
        position: relative; /* Ensure the button is positioned relative to the video */
    }
}

@media (max-width: 700px) {
    .Video {
        width: 100vw;
        height: 50vh;
        min-height: 282px;
        min-width: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .Video > .video-inner {
        display: flex;
        height: 277px;
        width: 500px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 20px;
        overflow: hidden;
        position: relative; /* Ensure the button is positioned relative to the video */
    }
}

@media (max-width: 500px) {
    .Video {
        width: 100%;
        height: 45vh;
        min-height: 282px;
        min-width: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .Video > .video-inner {
        display: flex;
        margin: auto;
        height: 250px;
        width: 400px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 20px;
        overflow: hidden;
        position: relative; /* Ensure the button is positioned relative to the video */
    }


}

@media (max-width: 400px) {
    .Video {
        width: 100%;
        height: 45vh;
        min-height: 282px;
        min-width: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .Video > .video-inner {
        display: flex;
        margin: auto;
        height: 169px;
        width: 300px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 20px;
        overflow: hidden;
        position: relative; /* Ensure the button is positioned relative to the video */
    }


}
