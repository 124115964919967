.Glassbox {
    width: 20vw; /* Use relative width */
    max-width: 120px;
    height: auto;
    max-height: 120px; /* Auto height to maintain aspect ratio */
    padding: 20px;
    background: -webkit-radial-gradient(circle, #87878715 0%, #c4c4c41e 50%, #d8d8d817 100%);
    background: radial-gradient(circle, #87878715 0%, #c4c4c41e 50%, #d8d8d817 100%);
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    text-align: center;
    margin: 10px; /* Adjust margin for spacing */
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px;
}

.Glassbox > h6{
    font-size: 20px !important;
    font-weight: 100;
}

.Glassbox > h1{
    font-size: 60px !important;
    font-weight: 650;
    letter-spacing: 10px !important;
    margin-right: -10px !important;
}

@media (max-width: 768px) {
    .Glassbox {
        width: 30vw; 
        max-width: 100px;
        max-height: 100px; 
    }
    
    .content > .content-header > .counter {
        justify-content: center; 
    }

    .Glassbox > h6{
        font-size: 15px !important;
        font-weight: 100;
    }
    
    .Glassbox > h1{
        font-size: 50px !important;
        font-weight: 650;
        letter-spacing: 10px;
        margin-right: -10px;
    }

}

@media (max-width: 480px) {
    .Glassbox {
        width: 30vw; /* Further increase relative width for very small screens */
        max-width: 60px;
        max-height: 60px; /* Adjust max-width */
    }

    .Glassbox > h6{
        font-size: 10px !important;
        font-weight: 100;
    }
    
    .Glassbox > h1{
        font-size: 30px !important;
        font-weight: 650;
        letter-spacing: 10px;
        margin-right: -10px;
    }
}
