.Showcase{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    background-color: transparent;
}

.Showcase > .upper {
    display: flex;
    margin-top: 100px;
    flex-direction: row;
    justify-content: space-between;
    width:60vw;
}

.Showcase > .upper > .left{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.Showcase > .upper > .left >h1 {
    font-size: 35px;
    font-weight: 800;
}

.Showcase > .upper > .left > p {
    margin-top: 20px;
    font-size: 18px;
    max-width: 400px;
}

.Showcase > .mid {
    margin: 200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60vw;
}

.Showcase > .mid > .right{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.Showcase > .mid > .right >h1 {
    font-size: 35px;
    font-weight: 800;
}

.Showcase > .mid > .right > p {
    margin-top:20px;
    font-size: 18px;
    max-width: 400px;
}

.Showcase > .low {
    margin-top: 200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    width: 60vw;    
    color: white;
}

.Showcase > .low > h2{
    font-size: x-large;
    font-weight: 300;
    color: #ffffff18;
    text-align: center;
    text-shadow: #49494985 0px 0px 1px;
}

.partners{
    height: 100px;
    margin:50px;
    margin-top: 140px;
    margin-bottom: 350px;
}

 .icons{
    width: 800px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.levels{
display: flex;
flex-direction: column;
align-items: center;
justify-self: center;
}


.levels > h1{
    font-weight:900;
    text-transform: uppercase;
    font-style:italic;
}

.levels > .content{
    height: 300px;
    margin-top: 50px;
    min-width: 1000px;
    width: 65vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.levels > .content > .product{
    display: flex;
    font-weight: 300;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media (max-width: 860px) {
    .Showcase > .mid {
        margin: 200px;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .levels > h1{
        font-weight:900;
        font-size: x-large;
        text-transform: uppercase;
        font-style:italic;
    }

    .levels > p{
        font-size: medium;
    }

    .levels > .content{
        height: 300px;
        margin-top: 50px;
        min-width: 400px;
        width: 50vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }

    .Showcase > .low > h2{
        font-size: large;
        font-weight: 300;
        text-align: center;
        color: #ffffff18;
        text-shadow: #49494985 0px 0px 1px;
    }

}

@media (max-width: 460px) {
    .Showcase > .mid {
        margin: 200px;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .levels > h1{
        font-weight:900;
        font-size: x-large;
        text-transform: uppercase;
        font-style:italic;
    }

    .levels > p{
        font-size: small;
    }

    .levels > .content{
        height: 300px;
        margin-top: 50px;
        width: 40vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }

    .Showcase > .low > h2{
        font-size: medium;
        text-align: center;
        font-weight: 300;
        color: #ffffff18;
        text-shadow: #49494985 0px 0px 1px;
    }

}