.mid {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .left {
    position: relative;
  }
  
 .mid > .right {
    width: 600px;
    margin-left: 120px;
  }

  .right > h1{
    font-size: 50px;
  }
  
  .right > p{
    font-size: 25px;
  }

  .carousel {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .carousel-btn {
    background-color: transparent;
    border: none;
    font-size: 34px;
    cursor: pointer;
    color: #000;
    padding: 10px;
    z-index: 1;
  }
  
  .carousel-btn.left {
    position: absolute;
    left: -30px;
  }
  
  .carousel-btn.right {
    position: absolute;
    right: -30px;
  }
  
  img {
    transition: transform 0.5s ease;
  }
  
  .carousel img {
    border-radius: 20px;
  }
  
  .store-logos {
    display: flex;
    justify-content: center;
    gap: 10px; /* Space between the logos */
    margin-top: 20px; /* Space above the logos */
  }
  
  .store-logos img {
    cursor: pointer; /* Change cursor to indicate clickable logos */
  }

  @media (max-width: 1080px) {
    .mid {
        display: flex;
        align-items: center;
        width:65vw;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        text-align: center;
        margin: 0;
      }
      .mid > .right {
        width: 300px;
        margin-left: 5;
      }
    }

  @media (max-width: 860px) {
  .mid {
      display: flex;
      align-items: center;
      width: 80vw;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      text-align: center;
      margin: 0;
    }
    .mid > .right {
      width: 300px;
      margin: 0;
    }
  }

  
    