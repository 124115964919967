/* Partnership.css */
.Partnership {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.Partnership .top {
    display: flex;
    width: 100%;
    justify-content: flex-start; /* Align the logo to the top left */
}

.Partnership .top img {
    margin-left: 20px; /* Add some spacing from the left */
}

.Partnership h1 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

.Partnership .container {
    display: flex;
    background-color: rgb(237, 237, 237);
    border-radius: 15px;
    justify-content: space-around;
    width: 100%;
    max-width: 1200px;
    margin-top: 40px;
}

.Partnership .container .gym, 
.Partnership .container .other {
    flex: 1;
    margin: 0 20px;
    text-align: center;
}

.Partnership .container .gym h1, 
.Partnership .container .other h1 {
    font-size: 20px;
    color: #555;
}

/* Styling the forms */
.gym-form, .other-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.gym-form .form-group, 
.other-form .form-group {
    margin-bottom: 15px;
    width: 100%;
    max-width: 400px;
}

.gym-form label, 
.other-form label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
}

.gym-form input, 
.other-form input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Submit button styles */
.submit-button {
    background-color: #F7C542; /* Green background */
    color: white; /* White text */
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
}

.submit-button:hover {
    background-color: #c2a760; /* Darker green on hover */
}

/* Responsive styles for phone screens */
@media only screen and (max-width: 768px) {
    .Partnership {
        padding: 10px;
    }

    .Partnership .top img {
        width: 80px; /* Adjust logo size for small screens */
        margin-left: 10px;
    }

    .Partnership h1 {
        font-size: 20px;
    }

    .Partnership .container {
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }

    .Partnership .container .gym, 
    .Partnership .container .other {
        margin: 10px 0;
    }

    .Partnership .container .gym h1, 
    .Partnership .container .other h1 {
        font-size: 18px;
    }

    .gym-form .form-group, 
    .other-form .form-group {
        max-width: 100%;
    }

    .submit-button {
        width: 100%; /* Full width on mobile */
    }
}
