.Competition {
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
}

.Competition > .container-header {
    margin-top: 50px;
    text-align: center;
}

.Competition > .container-header > h4 {
    font-size: 30px;
    font-weight: 500;
    color: #747474;
}

.Competition > .container-header > h1 {
    font-size: 70px;
    max-width: 620px;
    font-weight: 800;
}

.Competition > .container-mid {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Competition > .container-mid > p {
    color: black;
    margin: 50px;
    text-shadow: black 0px 0px 1px;
    font-size: 15px;
    max-width: 30vw;
    text-align: center;
}

.Competition > .container-mid > h3 {
    font-size: 50px;
    font-weight: 900;
    color: #F7C542;
    text-align: center;
}
.parallax {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  
  .parallax h1 {
    position: absolute;
    transform: translate(-50%, -50%);
    color: black;
  }
  
  .moving-truck {
    width: 1000px;
    height: auto;
    transition: transform 0.1s linear;
  }
  