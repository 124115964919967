.Model3D {
    height: 60vh;
    max-height: 530px;
    width: auto;
    margin: auto;
    position: absolute;
}


@media (max-height: 900px) {
    .Model3D {
        max-height: 400px;
        width: auto; 
    }
}

@media (max-height: 800px) and (max-width: 800px) {
    .Model3D {
        max-height: 350px;
        width: auto; 
    }
}
/* Media query for viewport heights less than 700px */

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}